import React, { useEffect, useLayoutEffect } from "react";
import RedemptionForm from "./Components/RedemptionForm";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-loading-skeleton/dist/skeleton.css";

import "./App.css";
import $ from "jquery";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import UserForm from "./Components/UserForm";
import Thankyou from "./Components/Thankyou";
import NoInternet from "./Components/NoInternet";
import CertDetails from "./Components/CertDetails";

import AccountantIndex from "./Components/admin/Accountant_Index/AccountantIndex";
import AddAccountant from "./Components/admin/AdminMain/AddAccountant";
import AddAdmin from "./Components/admin/AdminMain/AddAdmin";
import AddByCompanies from "./Components/admin/AdminMain/AddByCompanies";
import AddByJob from "./Components/admin/AdminMain/AddByJob";
import AddCard from "./Components/admin/AdminMain/AddCard";
import AddCutomer from "./Components/admin/AdminMain/AddCutomer";
import AddOsr from "./Components/admin/AdminMain/AddOsr";
import AddPavlina from "./Components/admin/AdminMain/AddPavlina";
import AddRep from "./Components/admin/AdminMain/AddRep";
import AddSitePlastic from "./Components/admin/AdminMain/AddSitePlastic";
import AddState from "./Components/admin/AdminMain/AddState";
import AddSubCompany from "./Components/admin/AdminMain/AddSubCompany";
import CertiDetails from "./Components/admin/AdminMain/CertiDetails";
import CertRecords from "./Components/admin/AdminMain/CertRecords";
import Dashboard from "./Components/admin/AdminMain/Dashboard";
import DelByUpload from "./Components/admin/AdminMain/DelByUpload";
import EditJob from "./Components/admin/AdminMain/EditJob";
import EditLockup from "./Components/admin/AdminMain/EditLockup";
import ExportSendMail from "./Components/admin/AdminMain/ExportSendMail";
import ManageAccountant from "./Components/admin/AdminMain/ManageAccountant";
import ManageAdmin from "./Components/admin/AdminMain/ManageAdmin";
import ManageByCompanies from "./Components/admin/AdminMain/ManageByCompanies";
import ManageByJob from "./Components/admin/AdminMain/ManageByJob";
import ManageCard from "./Components/admin/AdminMain/ManageCard";
import ManageCustomer from "./Components/admin/AdminMain/ManageCustomer";
import ManageJob from "./Components/admin/AdminMain/ManageJob";
import ManageLockup from "./Components/admin/AdminMain/ManageLockup";
import ManageMails from "./Components/admin/AdminMain/ManageMails";
import ManageOsr from "./Components/admin/AdminMain/ManageOsr";
import ManagePavlina from "./Components/admin/AdminMain/ManagePavlina";
import ManageRep from "./Components/admin/AdminMain/ManageRep";
import ManageSitePlastic from "./Components/admin/AdminMain/ManageSitePlastic";
import ManageState from "./Components/admin/AdminMain/ManageState";
import OldFile from "./Components/admin/AdminMain/OldFile";
import RandomCert from "./Components/admin/AdminMain/RandomCert";
import SendMail from "./Components/admin/AdminMain/SendMail";
import SubCompany from "./Components/admin/AdminMain/SubCompany";
import ViewCertificateDetail from "./Components/admin/AdminMain/ViewCertificateDetail";
import AddCustomer from "./Components/admin/Customer_panel/AddCustomer";
import Header from "./Components/admin/includes/Header";

import Login from "./Components/admin/login/Login";
import OsrIndex from "./Components/admin/Osr_panel/OsrIndex";
// import UploadOsr from "./Components/admin/Osr_panel/UploadOsr";
import ViewOsrHistroy from "./Components/admin/Osr_panel/ViewOsrHistroy";
import CardToMailed from "./Components/admin/Pavlina_panel/CardToMailed";
import MailMerge from "./Components/admin/Pavlina_panel/MailMerge";
import PavlinaIndex from "./Components/admin/Pavlina_panel/PavlinaIndex";
import SearchMplJob from "./Components/admin/Pavlina_panel/SearchMplJob";
import VirtualCard from "./Components/admin/Pavlina_panel/VirtualCard";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "./Components/admin/includes/NotFound";
import jwtDecode from "jwt-decode";
import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";

import EditCustomer from "./Components/admin/AdminMain/EditCustomer";
import EditSitePlastic from "./Components/admin/AdminMain/EditSitePlastic";
import EditOsr from "./Components/admin/AdminMain/EditOsr";
import EditSubCompany from "./Components/admin/AdminMain/EditSubCompany";
import EditAccountant from "./Components/admin/AdminMain/EditAccountant";
import EditRep from "./Components/admin/AdminMain/EditRep";
import EditPavlina from "./Components/admin/AdminMain/EditPavlina";
import EditAdmin from "./Components/admin/AdminMain/EditAdmin";
import EditState from "./Components/admin/AdminMain/EditState";
import EditCard from "./Components/admin/AdminMain/EditCard";
import AddJob from "./Components/admin/AdminMain/AddJob";
import HeaderUser from "./Components/HeaderUser";

import axios from "axios";
import Error429 from "./Components/admin/includes/Error429";
import { ToastContainer, toast } from "react-toastify";
import ManageJobDetail from "./Components/admin/AdminMain/ManageJobDetail";

import UserformSurvey from "./Components/UserformSurvey";

import RegisterForm from "./Components/RegisterForm";
import TestOurTech_thankyou from "./Components/TestOurTech_thankyou";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import NewAdmin from "./Components/admin/AdminSingle/NewAdmin";

function App() {
  const navigate = useNavigate();
  const locat = useLocation();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);

  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const {
    adminAccessToken,
    customerAccessToken,
    accountantAccessToken,
    osrAccessToken,
    pavlinaAccessToken,
  } = state;

  useEffect(() => {
    if (
      locat.pathname !== "/" &&
      locat.pathname !== "/error-429" &&
      locat.pathname !== "/survey-form" &&
      locat.pathname !== "/user-form" &&
      locat.pathname !== "/thank-you" &&
      locat.pathname !== "/survey-form/" &&
      locat.pathname !== "/user-form/" &&
      locat.pathname !== "/thank-you/" &&
      locat.pathname !== "/register/" &&
      locat.pathname !== "/register" &&
      locat.pathname !== "/register/registration-form" &&
      locat.pathname !== "/register/registration-form/" &&
      locat.pathname !== "/cert_details" &&
      locat.pathname !== "/privacy-policy" &&
      locat.pathname !== "/register/registration-thankyou"
    ) {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      if (customerAccessToken) {
        if (
          locat.pathname === "/add_customer" ||
          locat.pathname === "/add_customer/" ||
          locat.pathname === "/admin/customer" ||
          locat.pathname === "/admin/customer/" ||
          locat.pathname === "/3in1Step" ||
          locat.pathname === "/3in1Step/"
        ) {
          const decoded = jwtDecode(customerAccessToken);
          if (currentTime > decoded.exp) {
            toast.error("Customer request time out!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            userActions.logOut("customerLogOut");
            navigate("/admin/customer", { replace: true });
          } else if (
            locat.pathname === "/admin/customer" ||
            locat.pathname === "/admin/customer/"
          ) {
            navigate("/3in1Step", { replace: true });
          }
        }
      } else {
        if (
          locat.pathname === "/add_customer" ||
          locat.pathname === "/add_customer/" ||
          locat.pathname === "/3in1Step" ||
          locat.pathname === "/3in1Step/"
        ) {
          navigate("/admin/customer", { replace: true });
        }
      }

      if (accountantAccessToken) {
        if (
          locat.pathname === "/account_dashboard" ||
          locat.pathname === "/account_dashboard/" ||
          locat.pathname === "/admin/accountant" ||
          locat.pathname === "/admin/accountant/"
        ) {
          const decoded = jwtDecode(accountantAccessToken);
          if (currentTime > decoded.exp) {
            toast.error("Accountant request time out!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            userActions.logOut("accountantLogOut");
            navigate("/admin/accountant", { replace: true });
          } else if (
            locat.pathname === "/admin/accountant" ||
            locat.pathname === "/admin/accountant/"
          ) {
            navigate("/account_dashboard", { replace: true });
          }
        }
      } else {
        if (
          locat.pathname === "/account_dashboard" ||
          locat.pathname === "/account_dashboard/"
        ) {
          navigate("/admin/accountant", { replace: true });
        }
      }

      if (osrAccessToken) {
        if (
          locat.pathname === "/osr_dashboard" ||
          locat.pathname === "/osr_dashboard/" ||
          locat.pathname === "/view_osr_history" ||
          locat.pathname === "/view_osr_history/" ||
          locat.pathname === "/upload_osr" ||
          locat.pathname === "/upload_osr/" ||
          locat.pathname === "/admin/osr" ||
          locat.pathname === "/admin/osr/"
        ) {
          const decoded = jwtDecode(osrAccessToken);
          if (currentTime > decoded.exp) {
            toast.error("OSR request time out!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            userActions.logOut("osrLogOut");
            navigate("/admin/osr", { replace: true });
          } else if (
            locat.pathname === "/admin/osr" ||
            locat.pathname === "/admin/osr/"
          ) {
            navigate("/osr_dashboard", { replace: true });
          }
        }
      } else {
        if (
          locat.pathname === "/osr_dashboard" ||
          locat.pathname === "/osr_dashboard/" ||
          locat.pathname === "/view_osr_history" ||
          locat.pathname === "/view_osr_history/" ||
          locat.pathname === "/upload_osr" ||
          locat.pathname === "/upload_osr/"
        ) {
          navigate("/admin/osr", { replace: true });
        }
      }

      if (pavlinaAccessToken) {
        if (
          locat.pathname === "/searchcerti_pav" ||
          locat.pathname === "/searchcerti_pav/" ||
          locat.pathname === "/search_mpl_jobs" ||
          locat.pathname === "/search_mpl_jobs/" ||
          locat.pathname === "/searchcomp_pav" ||
          locat.pathname === "/searchcomp_pav/" ||
          locat.pathname === "/searchmail_pav_hard" ||
          locat.pathname === "/searchmail_pav_hard/" ||
          locat.pathname === "/searchmail_pav_virtual" ||
          locat.pathname === "/searchmail_pav_virtual/" ||
          locat.pathname === "/admin/pavlina" ||
          locat.pathname === "/admin/pavlina/"
        ) {
          const decoded = jwtDecode(pavlinaAccessToken);
          if (currentTime > decoded.exp) {
            toast.error("Pavlina request time out!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            userActions.logOut("pavlinaLogOut");
            navigate("/admin/pavlina", { replace: true });
          } else if (
            locat.pathname === "/admin/pavlina" ||
            locat.pathname === "/admin/pavlina/"
          ) {
            navigate("/searchcerti_pav", { replace: true });
          }
        }
      } else {
        if (
          locat.pathname === "/searchcerti_pav" ||
          locat.pathname === "/searchcerti_pav/" ||
          locat.pathname === "/search_mpl_jobs" ||
          locat.pathname === "/search_mpl_jobs/" ||
          locat.pathname === "/searchcomp_pav" ||
          locat.pathname === "/searchcomp_pav/" ||
          locat.pathname === "/searchmail_pav_hard" ||
          locat.pathname === "/searchmail_pav_hard/" ||
          locat.pathname === "/searchmail_pav_virtual" ||
          locat.pathname === "/searchmail_pav_virtual/"
        ) {
          navigate("/admin/pavlina", { replace: true });
        }
      }

      if (adminAccessToken) {
        if (
          locat.pathname !== "/admin/customer" &&
          locat.pathname !== "/admin/customer/" &&
          locat.pathname !== "/admin/accountant" &&
          locat.pathname !== "/admin/accountant/" &&
          locat.pathname !== "/admin/osr" &&
          locat.pathname !== "/admin/osr/" &&
          locat.pathname !== "/admin/pavlina" &&
          locat.pathname !== "/admin/pavlina/" &&
          locat.pathname !== "/add_customer" &&
          locat.pathname !== "/add_customer/" &&
          locat.pathname !== "/3in1Step" &&
          locat.pathname !== "/3in1Step/" &&
          locat.pathname !== "/searchcerti_pav" &&
          locat.pathname !== "/searchcerti_pav/" &&
          locat.pathname !== "/search_mpl_jobs" &&
          locat.pathname !== "/search_mpl_jobs/" &&
          locat.pathname !== "/searchcomp_pav" &&
          locat.pathname !== "/searchcomp_pav/" &&
          locat.pathname !== "/searchmail_pav_hard" &&
          locat.pathname !== "/searchmail_pav_hard/" &&
          locat.pathname !== "/searchmail_pav_virtual" &&
          locat.pathname !== "/searchmail_pav_virtual/" &&
          locat.pathname !== "/osr_dashboard" &&
          locat.pathname !== "/osr_dashboard/" &&
          locat.pathname !== "/view_osr_history" &&
          locat.pathname !== "/view_osr_history/" &&
          locat.pathname !== "/upload_osr" &&
          locat.pathname !== "/upload_osr/" &&
          locat.pathname !== "/account_dashboard" &&
          locat.pathname !== "/account_dashboard/"
        ) {
          const decoded = jwtDecode(adminAccessToken);
          if (currentTime > decoded.exp) {
            toast.error("Request time out!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            userActions.logOut("adminLogOut");
            navigate("/admin/login", { replace: true });
          } else if (
            locat.pathname === "/admin" ||
            locat.pathname === "/admin/" ||
            locat.pathname === "/login" ||
            locat.pathname === "/login/" ||
            locat.pathname === "/admin/login" ||
            locat.pathname === "/admin/login/"
          ) {
            navigate("/admin_dashboard", { replace: true });
          }
        }
      } else {
      }
    } else {
      const backToTop = () => {
        return (
          $("html, body").animate(
            {
              scrollTop: 0,
            },
            600
          ),
          !1
        );
      };
      backToTop();
    }

    // eslint-disable-next-line
  }, [locat.pathname]);

  useLayoutEffect(() => {
    localStorage.setItem("isVoucher", false);
    if (
      locat.pathname === "/" ||
      locat.pathname === "/survey-form" ||
      locat.pathname === "/user-form" ||
      locat.pathname === "/thank-you" ||
      locat.pathname === "/survey-form/" ||
      locat.pathname === "/user-form/" ||
      locat.pathname === "/thank-you/" ||
      locat.pathname === "/register/" ||
      locat.pathname === "/register" ||
      locat.pathname === "/register/registration-form" ||
      locat.pathname === "/register/registration-form/" ||
      locat.pathname === "/cert_details" ||
      locat.pathname === "/privacy-policy" ||
      locat.pathname === "/register/registration-thankyou"
    ) {
      var element = document.getElementById("root");
      element.classList.add("img-bg");
    }
  }, [locat.pathname]);

  // REDIRECTION not WORKING
  useEffect(() => {
    if (
      locat.pathname === "/admin/customer.php" ||
      locat.pathname === "/admin/osr" ||
      locat.pathname === "/admin/accountant" ||
      locat.pathname === "/admin/osr/" ||
      locat.pathname === "/admin/accountant/"
    ) {
      navigate("/admin/customer", { replace: true });
    } else if (locat.pathname === "/admin/osr.php") {
      navigate("/admin/customer", { replace: true });
    } else if (locat.pathname === "/admin/accountant.php") {
      navigate("/admin/customer", { replace: true });
    } else if (locat.pathname === "/admin/pavlina.php") {
      navigate("/admin/pavlina", { replace: true });
    } else if (locat.pathname === "/admin/searchcerti_pav.php") {
      navigate("/admin/pavlina", { replace: true });
    } else if (locat.pathname === "/admin/admin.php") {
      navigate("/admin/login", { replace: true });
    }
  }, [locat.pathname]);

  return (
    <NoInternet>
      <ToastContainer />
      {locat.pathname !== "/admin/login" &&
      locat.pathname !== "/admin/osr" &&
      locat.pathname !== "/admin/pavlina" &&
      locat.pathname !== "/admin/accountant" &&
      locat.pathname !== "/admin/customer" &&
      locat.pathname !== "/admin" &&
      locat.pathname !== "/admin/login/" &&
      locat.pathname !== "/admin/osr/" &&
      locat.pathname !== "/admin/pavlina/" &&
      locat.pathname !== "/admin/accountant/" &&
      locat.pathname !== "/admin/customer/" &&
      locat.pathname !== "/3in1Step" &&
      locat.pathname !== "/3in1Step/" &&
      locat.pathname !== "/admin/" &&
      locat.pathname !== "/admin/customer.php" &&
      locat.pathname !== "/admin/osr.php" &&
      locat.pathname !== "/admin/accountant.php" &&
      locat.pathname !== "/admin/pavlina.php" &&
      locat.pathname !== "/admin/admin.php" &&
      locat.pathname !== "/" &&
      locat.pathname !== "/survey-form" &&
      locat.pathname !== "/user-form" &&
      locat.pathname !== "/thank-you" &&
      locat.pathname !== "/survey-form/" &&
      locat.pathname !== "/user-form/" &&
      locat.pathname !== "/thank-you/" &&
      locat.pathname !== "/register/" &&
      locat.pathname !== "/register" &&
      locat.pathname !== "/register/registration-form" &&
      locat.pathname !== "/register/registration-form/" &&
      locat.pathname !== "/cert_details" &&
      locat.pathname !== "/privacy-policy" &&
      locat.pathname !== "/register/registration-thankyou" ? (
        <Header />
      ) : null}

      {locat.pathname === "/" ||
      locat.pathname === "/survey-form" ||
      locat.pathname === "/thank-you" ||
      locat.pathname === "/survey-form/" ||
      locat.pathname === "/user-form" ||
      locat.pathname === "/user-form/" ||
      locat.pathname === "/thank-you/" ||
      locat.pathname === "/register/" ||
      locat.pathname === "/register" ||
      locat.pathname === "/cert_details" ||
      locat.pathname === "/privacy-policy" ||
      locat.pathname === "/register/registration-form" ||
      locat.pathname === "/register/registration-form/" ||
      locat.pathname === "/register/registration-thankyou" ? (
        <HeaderUser />
      ) : null}

      <Routes>
        <Route path="/" element={<RedemptionForm />} />
        <Route path="/survey-form" element={<UserformSurvey />} />
        <Route path="/user-form" element={<UserForm />} />

        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/cert_details" element={<CertDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/*

        
        <Route path="/register" element={<RegisterForm />} />

        <Route
          path="/register/registration-thankyou"
          element={<TestOurTech_thankyou />}
        />
*/}
        {/* admin panel paths  */}
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/:type" element={<Login />} />
        <Route path="/3in1Step" element={<NewAdmin />} />

        <Route exact path="/account_dashboard" element={<AccountantIndex />} />
        <Route path="/add_customer" element={<AddCustomer />} />
        <Route path="/osr_dashboard" element={<OsrIndex />} />
        <Route path="/view_osr_history" element={<ViewOsrHistroy />} />
        {/* <Route path="/upload_osr" element={<UploadOsr />} /> */}
        <Route path="/searchcerti_pav" element={<PavlinaIndex />} />
        <Route path="/search_mpl_jobs" element={<SearchMplJob />} />
        <Route path="/searchcomp_pav" element={<MailMerge />} />
        <Route path="/searchmail_pav_hard" element={<CardToMailed />} />
        <Route path="/searchmail_pav_virtual" element={<VirtualCard />} />
        <Route
          path="/admin_dashboard"
          element={adminAccessToken ? <Dashboard /> : <Login />}
        />
        <Route
          path="/site_manage"
          element={adminAccessToken ? <ManageCustomer /> : <Login />}
        />
        <Route
          path="/site_add"
          element={adminAccessToken ? <AddCutomer /> : <Login />}
        />
        <Route
          path="/site_edit/:id"
          element={adminAccessToken ? <EditCustomer /> : <Login />}
        />
        <Route
          path="/sub_company"
          element={adminAccessToken ? <SubCompany /> : <Login />}
        />
        <Route
          path="/add_sub_company"
          element={adminAccessToken ? <AddSubCompany /> : <Login />}
        />
        <Route
          path="/edit_sub_company/:id"
          element={adminAccessToken ? <EditSubCompany /> : <Login />}
        />
        <Route
          path="/pickyourplastic_manage"
          element={adminAccessToken ? <ManageSitePlastic /> : <Login />}
        />
        <Route
          path="/pickyourplastic_add_site"
          element={adminAccessToken ? <AddSitePlastic /> : <Login />}
        />
        <Route
          path="/pickyourplastic_edit_site/:id"
          element={adminAccessToken ? <EditSitePlastic /> : <Login />}
        />
        <Route
          path="/osr_manage"
          element={adminAccessToken ? <ManageOsr /> : <Login />}
        />
        <Route
          path="/osr_add"
          element={adminAccessToken ? <AddOsr /> : <Login />}
        />
        <Route
          path="/osr_edit/:id"
          element={adminAccessToken ? <EditOsr /> : <Login />}
        />
        <Route
          path="/acc_manage"
          element={adminAccessToken ? <ManageAccountant /> : <Login />}
        />
        <Route
          path="/acc_add"
          element={adminAccessToken ? <AddAccountant /> : <Login />}
        />
        <Route
          path="/acc_edit/:id"
          element={adminAccessToken ? <EditAccountant /> : <Login />}
        />
        <Route
          path="/rep_manage"
          element={adminAccessToken ? <ManageRep /> : <Login />}
        />
        <Route
          path="/rep_add"
          element={adminAccessToken ? <AddRep /> : <Login />}
        />
        <Route
          path="/rep_edit/:id"
          element={adminAccessToken ? <EditRep /> : <Login />}
        />
        <Route
          path="/Managestatus"
          element={adminAccessToken ? <ManagePavlina /> : <Login />}
        />
        <Route
          path="/Addpav"
          element={adminAccessToken ? <AddPavlina /> : <Login />}
        />
        <Route
          path="/Editpav/:id"
          element={adminAccessToken ? <EditPavlina /> : <Login />}
        />
        <Route
          path="/lookup_manage"
          element={adminAccessToken ? <ManageLockup /> : <Login />}
        />
        <Route
          path="/lookup-edit/:id"
          element={adminAccessToken ? <EditLockup /> : <Login />}
        />
        <Route
          path="/card_manage"
          element={adminAccessToken ? <ManageCard /> : <Login />}
        />
        <Route
          path="/card_add"
          element={adminAccessToken ? <AddCard /> : <Login />}
        />
        <Route
          path="/card_edit/:id"
          element={adminAccessToken ? <EditCard /> : <Login />}
        />
        <Route
          path="/ManageAdmin"
          element={adminAccessToken ? <ManageAdmin /> : <Login />}
        />
        <Route
          path="/AddAdmin"
          element={adminAccessToken ? <AddAdmin /> : <Login />}
        />
        <Route
          path="/EditAdmin/:id"
          element={adminAccessToken ? <EditAdmin /> : <Login />}
        />
        <Route
          path="/OldFile"
          element={adminAccessToken ? <OldFile /> : <Login />}
        />
        <Route
          path="/job_manage"
          element={adminAccessToken ? <ManageJob /> : <Login />}
        />
        <Route
          path="/job_detail/:job/:group"
          element={adminAccessToken ? <ManageJobDetail /> : <Login />}
        />
        <Route
          path="/job_add"
          element={adminAccessToken ? <AddJob /> : <Login />}
        />
        <Route
          path="/job_edit/:group"
          element={adminAccessToken ? <EditJob /> : <Login />}
        />
        <Route
          path="/certificat_details"
          element={adminAccessToken ? <ViewCertificateDetail /> : <Login />}
        />
        <Route
          path="/certdetail/:status/:company"
          element={adminAccessToken ? <CertiDetails /> : <Login />}
        />
        <Route
          path="/export_sendmail"
          element={adminAccessToken ? <ExportSendMail /> : <Login />}
        />
        <Route
          path="/manage_mails"
          element={adminAccessToken ? <ManageMails /> : <Login />}
        />
        <Route
          path="/sendmail/:groupNo"
          element={adminAccessToken ? <SendMail /> : <Login />}
        />
        <Route path="/random_cert" element={<RandomCert />} />
        <Route
          path="/manage_by_company"
          element={adminAccessToken ? <ManageByCompanies /> : <Login />}
        />
        <Route
          path="/job_add_by_company"
          element={adminAccessToken ? <AddByCompanies /> : <Login />}
        />
        <Route
          path="/manage_by_job"
          element={adminAccessToken ? <ManageByJob /> : <Login />}
        />
        <Route
          path="/job_add_by_job"
          element={adminAccessToken ? <AddByJob /> : <Login />}
        />
        <Route
          path="/dell_all"
          element={adminAccessToken ? <DelByUpload /> : <Login />}
        />
        <Route
          path="/state_manage"
          element={adminAccessToken ? <ManageState /> : <Login />}
        />
        <Route
          path="/state_add"
          element={adminAccessToken ? <AddState /> : <Login />}
        />
        <Route
          path="/state_edit/:id"
          element={adminAccessToken ? <EditState /> : <Login />}
        />
        <Route
          path="/cert-record"
          element={adminAccessToken ? <CertRecords /> : <Login />}
        />
        <Route path="/error-429" element={<Error429 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="fix-white"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
